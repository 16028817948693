import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CPRS_CERTIFICATION_APPLICATION_QUERY } from "@/graphql/cprs-certification-application-query";
import {
  CertificationPartTwoForm,
  FormFieldContents,
  FormNavbar,
  addReference,
  addSupervisor,
} from "@/models/certification-part-two-form/certification-part-two-form.interface";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import { certificationFormApi } from "@/providers/apis/certification-part-two-form";
import DateTimePickerComponent from "../date-time-picker/date-time-picker.component.vue";
import CertificationPartTwoPreviewForm from "../certification-part-two-preview-form/certification-part-two-preview-form.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { utils } from "@/utils/utils";
import MultiRadioButton from "../multi-radio-button/multi-radio-button.component.vue";
import moment from "moment";
import { User } from "@/models/common-interface/common.interface";
import ScopeOfActivity from "../scope-of-activity/scope-of-activity-component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import EligibilityPopupComponent from "../eligibility-qsn-valid-popup/eligibility-qsn-valid-popup.vue";

@Component({
  name: "certification-part-two-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-multi-radio": MultiRadioButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-date-time-picker": DateTimePickerComponent,
    "certification-preview-form": CertificationPartTwoPreviewForm,
    "code-of-ethics-and-scope-of-activity": ScopeOfActivity,
    AppLoaderComponent,
    ErrorPopupComponent,
    EligibilityPopupComponent,
  },
})
export default class CertificationPartTwoFormComponent extends Vue {
  public isScroll = false;
  public applicationData: Array<CertificationPartTwoForm> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public formData: Array<FormFieldContents> = [];
  public isNavLoad = false;
  public formPagination = 0;
  public isValid = false;
  public fileData: any;
  public previewPayload: any = {};
  public applicantFullDetials: any;
  public formName = "certificationPartTwoApplicationForm";
  public certificateLocationId: any;
  public referenceEmailDuplicateIndexs: number[] = [];
  public supervisorEmailDuplicateIndexs: number[] = [];
  public supervisorFormFields: addSupervisor[] = [];
  public supervisorPayLoadData: any[] = [];
  public signatureInvalid = false;
  public saveDataDraft = false;
  public userDetails: User = {} as User;
  public isReviewerUser: any;
  public formTitle = {
    certificationTitle: "",
    certificationSubTitle: "",
  };
  public certificatePayLoadData: any = {
    fillStatus: "DRAFT",
    certificationPartTwoApplicationForm: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      backupEmail: null,
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
      agencyOrOrganization: "",
      positionOrTitle: "",
      stillEmployed: "",
      startDate: "",
      endDate: "",
      employmentStatus: [],
      completedTennessee: "",
      employedOrVolunteered: "",
      receivedThreeHoursSupervision: "",
      descripePeerSupportResponsibity: "",
    },
    certificationPartTwoSupervisorInformation: [
      {
        supervisorFirstName: "",
        supervisorLastName: "",
        supervisorAgencyOrganization: "",
        supervisorPhone: "",
        supervisorEmail: "",
      },
    ],
    certificationPartTwoRefererenceInformation: [
      {
        firstName: "",
        lastName: "",
        agencyOrganization: "",
        yourPosition: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
      },
      {
        firstName: "",
        lastName: "",
        agencyOrganization: "",
        yourPosition: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
      },
      {
        firstName: "",
        lastName: "",
        agencyOrganization: "",
        yourPosition: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
      },
    ],
    certificationPartTwoHandbook: {
      handbook: {
        codeOfEthics: {
          isCodeofEthicsChecked: [],
          codeofEthicsFirstName: "",
          codeofEthicsLastName: "",
        },
        scopeOfActivity: {
          isScopeofActivityChecked: [],
          scopeofActivityFirstName: "",
          scopeofActivityLastName: "",
        },
      },
    },
    certificationPartTwoSignature: {
      isSignature: "",
      submissionDate: "",
    },
  };
  public isLoading = false;
  public isEdit = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public checkValidForm = false;
  public formCompletedIndex: number[] = [];
  public mainId = "app";
  public hideDiv: string[] = ["startDate", "endDate"];
  public checkField: string[] = ["stillEmployed"];
  public addReferenceForm: addReference[] = [];
  public isReferesh = "false";
  public checkSOAValid = false;
  public validSOA = true;
  public errorMessage = "";
  public supervisorEndDateShowIdx: number[] = [];
  public supervisorStartDateShowIdx: number[] = [];
  public supervisorUIUpdate = false;
  public eligibilityPopup = false;

  public created() {
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }
  public async mounted() {
    this.userDetails = utils.getUserDetails();
    const token = localStorage.getItem("access_token");
    const formFilledIndex: any = localStorage.getItem("partTwoFormFilledIndex");
    if (formFilledIndex) {
      formFilledIndex.split(",").forEach((data: string) => {
        this.formCompletedIndex.push(Number(data));
      });
    } else {
      if (!this.formCompletedIndex.includes(this.formPagination)) {
        this.formCompletedIndex.push(this.formPagination);
      }
    }
    if (this.userDetails) {
      const userRolesData = this.userDetails.roles;
      this.isReviewerUser =
        userRolesData && userRolesData.indexOf("REVIEW_APPLICATION") !== -1;
    } else {
      this.isReviewerUser = false;
    }
    let payloadStorage: any = localStorage.getItem(
      "partTwoCertificationPayload"
    );
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.certificatePayLoadData = { ...payloadStorage };
    } else {
      if (
        !this.isReviewerUser &&
        token &&
        this.userDetails &&
        (this.userDetails.roles === null || !this.userDetails.roles)
      ) {
        this.isLoading = true;
        const userDetailsEmail = this.userDetails.email;
        certificationFormApi
          .getCertificateDetials(userDetailsEmail)
          .then((result: any) => {
            this.applicantFullDetials = result.data.data;
            this.certificatePayLoadData.certificationPartTwoApplicationForm.firstName =
              this.applicantFullDetials.certificationPartOneBasicDetails.firstName;
            this.certificatePayLoadData.certificationPartTwoApplicationForm.lastName =
              this.applicantFullDetials.certificationPartOneBasicDetails.lastName;
            this.certificatePayLoadData.certificationPartTwoApplicationForm.phone =
              this.applicantFullDetials.certificationPartOneBasicDetails.phone;
            this.certificatePayLoadData.certificationPartTwoApplicationForm.email =
              this.applicantFullDetials.certificationPartOneBasicDetails.email;
            this.certificatePayLoadData.certificationPartTwoApplicationForm.addressLine1 =
              this.applicantFullDetials.certificationPartOneBasicDetails.addressLine1;
            this.certificatePayLoadData.certificationPartTwoApplicationForm.addressLine2 =
              this.applicantFullDetials.certificationPartOneBasicDetails.addressLine2;
            this.certificatePayLoadData.certificationPartTwoApplicationForm.state =
              this.applicantFullDetials.certificationPartOneBasicDetails.state;
            this.certificatePayLoadData.certificationPartTwoApplicationForm.city =
              this.applicantFullDetials.certificationPartOneBasicDetails.city;
            this.certificatePayLoadData.certificationPartTwoApplicationForm.zip =
              this.applicantFullDetials.certificationPartOneBasicDetails.zip;
            localStorage.setItem(
              "partTwoCertificationPayload",
              JSON.stringify(this.certificatePayLoadData)
            );
            this.isLoading = false;
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        this.$router.push({
          name: "login-page",
          query: { application: "certification-part-two-form" },
        });
      }
    }
    if (this.formPagination === 0) {
      this.isNavLoad = true;
    }
    this.$apollo
      .query({
        query: gql`query ${CPRS_CERTIFICATION_APPLICATION_QUERY}`,
      })
      .then((result) => {
        this.applicationData =
          result.data.cprsCertificationApplicationForms.data;
        this.formTitle = {
          certificationTitle:
            this.applicationData[0].attributes.certificationTitle,
          certificationSubTitle:
            this.applicationData[0].attributes.certificationSubTitle,
        };
        this.applicationNavData =
          this.applicationData[0].attributes.certificationNavbar;
        this.formData =
          this.applicationData[0].attributes.certification_form_fields;
        if (
          this.certificatePayLoadData.certificationPartTwoSupervisorInformation
            .length
        ) {
          const addSupervisorForm =
            this.certificatePayLoadData
              .certificationPartTwoSupervisorInformation.length;
          for (let index = 0; index < addSupervisorForm; index++) {
            this.supervisorFormFields.push(
              this.applicationData[0].attributes.certification_form_fields[1]
                .fields.formInputs[0]
            );
          }
        }
        for (let index = 0; index < this.checkField.length; index++) {
          this.fieldUpdate(this.checkField[index]);
        }
      });
    const mainEl: any = document.querySelector(".main-content");
    if (mainEl) {
      mainEl.addEventListener("keyup", (event: any) => {
        if (event.shiftKey && event.keyCode == 9) {
          const el = event.path[0];
          if (el) {
            el.scrollIntoView({ block: "center", behavior: "smooth" });
          }
        }
      });
    }
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public nextButton() {
    this.checkValidForm = true;
    this.checkSOAValid = !this.checkSOAValid;
    this.certificatePayLoadData.certificationPartTwoApplicationForm.state =
      this.certificatePayLoadData.certificationPartTwoApplicationForm.state.toLocaleUpperCase();
    if (this.formPagination == 3) {
      this.certificatePayLoadData.certificationPartTwoSignature.submissionDate =
        moment().format("MM-DD-YYYY");
    }
    if (this.formPagination == 4) {
      this.certificatePayLoadData.certificationPartTwoSignature.submissionDate =
        moment().format("MM-DD-YYYY");
      if (
        this.certificatePayLoadData.certificationPartTwoSignature.isSignature
      ) {
        this.signatureInvalid = false;
      } else {
        this.checkValidForm = true;
        this.signatureInvalid = true;
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 100);
        return;
      }
    }
    this.$validator.validateAll().then(async (validate) => {
      if (this.formName === "certificationPartTwoRefererenceInformation") {
        this.referenceEmailDuplicateIndexs = utils.duplicateValueIdx(
          this.certificatePayLoadData,
          this.formName,
          true,
          "referenceEmailAddress"
        );
      }
      if (this.formName === "certificationPartTwoSupervisorInformation") {
        this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
          this.certificatePayLoadData,
          this.formName,
          true,
          "supervisorEmail"
        );
      }

      if (
        this.referenceEmailDuplicateIndexs.length === 0 &&
        this.supervisorEmailDuplicateIndexs.length === 0 &&
        validate &&
        this.validSOA
      ) {
        if (this.formPagination == 1) {
          const supervisorEmailValidate =
            this.certificatePayLoadData.certificationPartTwoSupervisorInformation.filter(
              (supervisor: any) => {
                if (
                  supervisor.supervisorEmail.toLowerCase() ===
                  this.certificatePayLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                ) {
                  return supervisor;
                }
              }
            );
          if (supervisorEmailValidate.length) {
            this.errorMessage =
              "Supervisor email id and applicant email id must be different *";
            return;
          }
        }

        if (this.formPagination == 2) {
          const duplicateEmail =
            this.certificatePayLoadData.certificationPartTwoRefererenceInformation.filter(
              (reference: any) => {
                if (
                  reference.referenceEmailAddress.toLowerCase() ===
                  this.certificatePayLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                ) {
                  return reference;
                }
              }
            );
          if (duplicateEmail.length) {
            this.errorMessage =
              "Applicant email id and reference email id must be different *";
            return;
          }
          const supervisorEmails =
            this.certificatePayLoadData.certificationPartTwoSupervisorInformation.map(
              (supervisor: any) => {
                return supervisor.supervisorEmail.toLowerCase();
              }
            );
          const supervisorReferenceDuplicateEmail =
            this.certificatePayLoadData.certificationPartTwoRefererenceInformation.filter(
              (reference: any) => {
                if (
                  supervisorEmails.includes(
                    reference.referenceEmailAddress.toLowerCase()
                  )
                ) {
                  return reference;
                }
              }
            );
          if (supervisorReferenceDuplicateEmail.length) {
            this.errorMessage =
              "Supervisor email id and reference email id must be different *";
            this.showForm(this.formName);
            return;
          }
        }

        this.isLoading = true;
        this.checkValidForm = false;
        this.isValid = true;
        if (!this.certificatePayLoadData.id) {
          await certificationFormApi
            .CertificationApplicationForm(
              this.certificatePayLoadData.fillStatus,
              this.certificatePayLoadData
            )
            .then((result: any) => {
              this.isLoading = false;
              this.certificateLocationId = result.headers.location;
              localStorage.setItem(
                "partTwoCertificateApplicationId",
                this.certificateLocationId
              );
              this.certificatePayLoadData.id = localStorage.getItem(
                "partTwoCertificateApplicationId"
              );
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          const applicationId = localStorage.getItem(
            "partTwoCertificateApplicationId"
          );
          let payloadStorage: any = localStorage.getItem(
            "partTwoCertificationPayload"
          );
          if (applicationId || payloadStorage) {
            payloadStorage = JSON.parse(payloadStorage);
            this.certificatePayLoadData.id =
              applicationId === null ? payloadStorage.id : applicationId;
          }
          const id = this.certificatePayLoadData.id;
          await certificationFormApi
            .updateCertificationForm(this.certificatePayLoadData, id)
            .then((data) => {
              this.isLoading = false;
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        }
        if (this.isEdit) {
          this.formPagination = 5;
          this.formName = "certificationPartTwoApplicationForm";
        }
        if (!this.formCompletedIndex.includes(this.formPagination)) {
          this.formCompletedIndex.push(this.formPagination);
        }
        localStorage.setItem(
          "partTwoFormFilledIndex",
          this.formCompletedIndex.toString()
        );
        localStorage.setItem(
          "partTwoCertificationPayload",
          JSON.stringify(this.certificatePayLoadData)
        );
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
      }
    });
  }

  public nextPaginationUpdate() {
    if (this.formPagination < this.formData.length - 1) {
      this.isNavLoad = true;
      const page = this.formPagination + 1;
      if (page == 0) {
        this.formName = "certificationPartTwoApplicationForm";
      } else if (page == 1) {
        this.formName = "certificationPartTwoSupervisorInformation";
      } else if (page == 2) {
        this.formName = "certificationPartTwoRefererenceInformation";
        this.showForm(this.formName);
      } else if (page == 3) {
        this.formName = "certificationPartTwoHandbook";
      } else if (page == 4) {
        this.formName = "certificationPartTwoSignature";
      }
      this.formPagination = page;
    }
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    localStorage.setItem(
      "partTwoCertificationPayload",
      JSON.stringify(this.certificatePayLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  formEdit(slug: any) {
    this.isEdit = true;
    this.formName = slug;
    if (slug == "certificationPartTwoApplicationForm") {
      this.formPagination = 0;
    } else if (slug == "certificationPartTwoSupervisorInformation") {
      this.formPagination = 1;
    } else if (slug == "certificationPartTwoRefererenceInformation") {
      this.showForm(slug);
      this.formPagination = 2;
    }
    if (slug == "certificationPartTwoHandbook") {
      this.formPagination = 3;
    } else if (slug == "certificationPartTwoSignature") {
      this.certificatePayLoadData.certificationPartTwoSignature.isSignature =
        "";
      localStorage.setItem(
        "partTwoCertificationPayload",
        JSON.stringify(this.certificatePayLoadData)
      );
      this.formPagination = 4;
    }
    const el = document.getElementById(this.mainId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  public previousButton() {
    this.isEdit = false;
    this.referenceEmailDuplicateIndexs = [];
    this.supervisorEmailDuplicateIndexs = [];
    if (this.formPagination !== 0) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      this.formPagination = this.formPagination - 1;
      if (this.formPagination == 0) {
        this.formName = "certificationPartTwoApplicationForm";
      } else if (this.formPagination == 1) {
        this.formName = "certificationPartTwoSupervisorInformation";
      } else if (this.formPagination == 2) {
        this.formName = "certificationPartTwoRefererenceInformation";
      } else if (this.formPagination == 3) {
        this.formName = "certificationPartTwoHandbook";
      } else if (this.formPagination == 4) {
        this.formName = "certificationPartTwoSignature";
      }
    }
  }
  public onSubmit() {
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.certificatePayLoadData.certificationPartTwoApplicationForm.state =
          this.certificatePayLoadData.certificationPartTwoApplicationForm.state.toLocaleUpperCase();
        this.certificatePayLoadData.certificationPartTwoApplicationForm.submissionDate =
          moment().format("MM-DD-YYYY");
        let payloadStorage: any = localStorage.getItem(
          "partTwoCertificationPayload"
        );
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.certificatePayLoadData = { ...payloadStorage };
        }
        this.isLoading = true;
        this.certificatePayLoadData.fillStatus = "COMPLETED";
        this.certificatePayLoadData.id = localStorage.getItem(
          "partTwoCertificateApplicationId"
        );
        if (
          this.certificatePayLoadData.certificationPartTwoApplicationForm
            .backupEmail === ""
        ) {
          this.certificatePayLoadData.certificationPartTwoApplicationForm.backupEmail =
            null;
        }
        const id = this.certificatePayLoadData.id;
        certificationFormApi
          .updateCertificationForm(this.certificatePayLoadData, id)
          .then(() => {
            certificationFormApi
              .getCertificationApplicationForm(id)
              .then((result: any) => {
                const applicationCode = result.data.code;
                const applicationNameData = {
                  title: "Certified Peer Recovery Specialist Application",
                  SubTitle: "Certification: Part Two",
                };
                this.$router.push({
                  path: "/form/submitted",
                  query: {
                    subTitle: applicationNameData.SubTitle,
                    applicationId: applicationCode,
                  },
                });
                this.isLoading = false;
              })
              .catch((error) => {
                this.errorMessage = utils.errorMessage(error);
                this.isLoading = false;
              });
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            localStorage.removeItem("partTwoCertificationPayload");
            localStorage.removeItem("partTwoCertificateApplicationId");
            localStorage.removeItem("partTwoFormFilledIndex");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      }
    });
  }

  public scopeOfActivityValidation(event: boolean) {
    this.validSOA = event;
  }

  public navigateTab(slug: string, index: number) {
    if (this.formCompletedIndex.includes(index)) {
      this.isEdit = false;
      if (index > this.formPagination) {
        this.checkSOAValid = !this.checkSOAValid;
        if (index > 1) {
          this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.certificatePayLoadData,
            "certificationPartTwoSupervisorInformation",
            true,
            "supervisorEmail"
          );
          if (this.supervisorEmailDuplicateIndexs.length) {
            this.formPagination = 1;
            this.formName = "certificationPartTwoSupervisorInformation";
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            return;
          }
          const supervisorEmailValidate =
            this.certificatePayLoadData.certificationPartTwoSupervisorInformation.filter(
              (supervisor: any) => {
                if (
                  supervisor.supervisorEmail.toLowerCase() ===
                  this.certificatePayLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                ) {
                  return supervisor;
                }
              }
            );
          if (supervisorEmailValidate.length) {
            this.formPagination = 1;
            this.formName = "certificationPartTwoSupervisorInformation";
            this.errorMessage =
              "Supervisor email id and applicant email id must be different *";
            return;
          }
        }
        if (index > 2) {
          this.referenceEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.certificatePayLoadData,
            "certificationPartTwoRefererenceInformation",
            true,
            "referenceEmailAddress"
          );
          if (this.referenceEmailDuplicateIndexs.length) {
            this.formPagination = 2;
            this.formName = "certificationPartTwoRefererenceInformation";
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            return;
          }
          const referenceEmailValidate =
            this.certificatePayLoadData.certificationPartTwoRefererenceInformation.filter(
              (reference: any) => {
                if (
                  reference.referenceEmailAddress.toLowerCase() ===
                  this.certificatePayLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                ) {
                  return reference;
                }
              }
            );
          if (referenceEmailValidate.length) {
            this.formPagination = 2;
            this.formName = "certificationPartTwoRefererenceInformation";
            this.errorMessage =
              "Reference email id and applicant email id must be different *";
            return;
          }

          const supervisorEmails =
            this.certificatePayLoadData.certificationPartTwoSupervisorInformation.map(
              (supervisor: any) => {
                return supervisor.supervisorEmail.toLowerCase();
              }
            );
          const supervisorReferenceDuplicateEmail =
            this.certificatePayLoadData.certificationPartTwoRefererenceInformation.filter(
              (reference: any) => {
                if (
                  supervisorEmails.includes(
                    reference.referenceEmailAddress.toLowerCase()
                  )
                ) {
                  return reference;
                }
              }
            );
          if (supervisorReferenceDuplicateEmail.length) {
            this.formPagination = 2;
            this.formName = "certificationPartTwoRefererenceInformation";
            this.errorMessage =
              "Supervisor email id and reference email id must be different *";
            this.showForm(this.formName);
            return;
          }
        }

        //signature validation
        this.checkValidForm = true;
        this.certificatePayLoadData.certificationPartTwoSignature.submissionDate =
          moment().format("MM-DD-YYYY");
        if (this.formPagination == 5) {
          if (
            this.certificatePayLoadData.certificationPartTwoSignature
              .isSignature
          ) {
            this.signatureInvalid = false;
          } else {
            this.checkValidForm = true;
            this.signatureInvalid = true;
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 100);
            return;
          }
        }

        this.$validator.validateAll().then((validate: any) => {
          if (
            this.referenceEmailDuplicateIndexs.length === 0 &&
            this.supervisorEmailDuplicateIndexs.length === 0 &&
            validate &&
            this.validSOA
          ) {
            this.checkValidForm = false;
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            this.isValid = true;
            localStorage.setItem(
              "partTwoCertificationPayload",
              JSON.stringify(this.certificatePayLoadData)
            );
            if (index === 5) {
              this.formPagination = index;
              this.formName = "certificationPartTwoApplicationForm";
            } else {
              this.formName = slug;
              this.formPagination = index;
              this.showForm(this.formName);
            }
          } else {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }
        });
      } else {
        this.referenceEmailDuplicateIndexs = [];
        this.supervisorEmailDuplicateIndexs = [];
        if (index === 5) {
          this.formPagination = index;
          this.formName = "certificationPartTwoApplicationForm";
        } else {
          this.formName = slug;
          this.formPagination = index;
          this.showForm(this.formName);
        }
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  public fieldUpdate(groupName = "") {
    switch (groupName) {
      case "completedTennessee":
        if (
          this.certificatePayLoadData.certificationPartTwoApplicationForm
            .completedTennessee === "No"
        ) {
          // this.eligibilityPopup = true;
        }
        break;
      case "stillEmployed":
        if (
          this.certificatePayLoadData.certificationPartTwoApplicationForm
            .stillEmployed === "No"
        ) {
          if (this.hideDiv.indexOf("endDate") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("endDate"), 1);
          }
          if (this.hideDiv.indexOf("startDate") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("startDate"), 1);
          }
        } else if (
          this.certificatePayLoadData.certificationPartTwoApplicationForm
            .stillEmployed === "Yes"
        ) {
          if (!this.hideDiv.includes("endDate")) {
            this.hideDiv.push("endDate");
            this.certificatePayLoadData.certificationPartTwoApplicationForm.endDate =
              "";
          }
          if (this.hideDiv.indexOf("startDate") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("startDate"), 1);
          }
        }
        break;
      default:
        break;
    }
  }

  public changeStateForTraining(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public showForm(formName: string) {
    const formData = this.certificatePayLoadData[formName];
    if (formData.length) {
      if (formName === "certificationPartTwoRefererenceInformation") {
        this.addReferenceForm = [];
      }
      formData.forEach(() => {
        if (formName === "certificationPartTwoRefererenceInformation") {
          this.addReferenceForm.push(this.formData[2].fields.multiForm[0]);
        }
      });
    }
  }

  public pushForm(formName: string) {
    if (formName === "certificationPartTwoRefererenceInformation") {
      this.addReferenceForm.push(this.formData[2].fields.multiForm[0]);
      this.certificatePayLoadData.certificationPartTwoRefererenceInformation.push(
        {
          firstName: "",
          lastName: "",
          agencyOrganization: "",
          yourPosition: "",
          referencePhoneNumber: "",
          referenceEmailAddress: "",
          reference:
            this.certificatePayLoadData
              .certificationPartTwoRefererenceInformation.length + 1,
        }
      );
    }
  }

  public addAnother() {
    this.certificatePayLoadData.certificationPartTwoSupervisorInformation.push({
      supervisorFirstName: "",
      supervisorLastName: "",
      supervisorAgencyOrganization: "",
      supervisorPhone: "",
      supervisorEmail: "",
    });

    this.supervisorFormFields.push(
      this.applicationData[0].attributes.certification_form_fields[1].fields
        .formInputs[0]
    );
    this.supervisorUIUpdate = utils.UIUpdateUsingKey(this.supervisorUIUpdate);
  }

  public removeAddedSupervisor(indexForm: number) {
    this.certificatePayLoadData.certificationPartTwoSupervisorInformation.splice(
      indexForm,
      1
    );
    this.supervisorFormFields.splice(indexForm, 1);
    this.supervisorUIUpdate = utils.UIUpdateUsingKey(this.supervisorUIUpdate);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
  public goBack() {
    if (this.$route.query.isDraft) {
      localStorage.removeItem("partTwoCertificationPayload");
    }
    this.$router.push({
      path: `/application`,
      query: {
        form: "part-two",
      },
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
  }
  public closedEligibilityPopup(event: any) {
    this.eligibilityPopup = false;
    if (event) {
      this.goBack();
    }
  }
}
